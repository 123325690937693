// Roboto font

@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/Roboto-BlackItalic.ttf') format('truetype');
}

html, body {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

body {
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    display: none;
  }
}

button {
  font-family: inherit;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: inherit;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}
