@font-face {
  font-family: Brewhouse;
  src: url('/fonts/Brewhouse.ttf') format('truetype');
}

@font-face {
  font-family: 'We Are Cleveland';
  src: url('/fonts/We-Are-Cleveland.ttf') format('truetype');
}

:root {
  --cbh-red: #ea0029;
  --cbh-white: #fff;
  --cbh-black: #000;
  --cbh-text: #404040;
  --cbh-grey-light: #eaeaea;
  --cbh-grey-medium: #7a7a7a;
  --cbh-grey: #54595f;
}

body {
  font-family: 'We Are Cleveland', sans-serif;
}
